// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-product-faq-jsx": () => import("./../../../src/pages/productFAQ.jsx" /* webpackChunkName: "component---src-pages-product-faq-jsx" */),
  "component---src-pages-product-inner-jsx": () => import("./../../../src/pages/productInner.jsx" /* webpackChunkName: "component---src-pages-product-inner-jsx" */),
  "component---src-pages-question-jsx": () => import("./../../../src/pages/question.jsx" /* webpackChunkName: "component---src-pages-question-jsx" */)
}

